.applist__header {
  background: white;
  padding: 4rem 2.5rem 1rem 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 720px) {
    padding: 1.5rem 2.5rem 1.5rem 2.5rem;
  }

  .title {
      font-weight: 500;
    font-size: 1.75rem;
  }

  .icon {
    > i {
      font-size: 1.75rem;
      color: #a2a2a2;
    }
  }
}
