@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
}

a {
  text-decoration: none;
}

body {
  overflow-x: hidden;
}

img {
  display: block;
}
