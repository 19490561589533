.applist__item {
  margin: 1rem auto;
  background: #fff;
  padding: 1.5rem 2rem;
  box-shadow: 0px 0px 8px 0px #f0f0f0;
  border-radius: 5px;
  width: 95%;

  .item__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .item__left {
      display: flex;
      .square__thumb {
        width: 48px;
        height: 48px;
        border-radius: 5px;
      }


      .item__details{
          margin-left: .5rem;
          .item__app{
              font-size: 1.25rem;
              font-weight: 500;
          }
          .item__publisher{
              color: #666665;
              font-size: .65rem;
          }
      }
    }

    .item__icon {
      color: #c3c3c3;
      > i {
        font-size: 1.25rem;
      }
    }
  }


  .item__stats{
      margin-top: 1rem;
      display: flex;
  }
}
