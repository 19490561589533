.details__wrapper {
  padding: 2rem;
  width: 100%;

  .details__content__header {
    display: flex;
    align-items: center;
    .back__icon {
      color: #c3c3c3;
      font-size: 1.5rem;
      margin-right: 2rem;
    }

    .square__thumb {
      height: 64px;
      width: 64px;
      border-radius: 5px;
      margin: 0.75rem;
    }

    .app__details {
      .app__name {
        font-weight: 500;
        font-size: 1.5rem;
      }
      .app__publisher {
        font-size: 0.75rem;
      }
    }
  }

  .details__content {
    margin-top: 3rem;
    background: white;
    padding: 2rem;
    box-shadow: 0px 0px 2px 1px #e7e7e7;

    @media screen and (max-width: 720px) {
      overflow-x: scroll;
    }

    .details__table {
      width: 100%;

      > thead {
        text-align: left;
        th {
          padding: 1rem 0 1.5rem 0;
          font-weight: 500;
        }
      }

      > tbody {
        > tr {
          > td {
            padding: 0.6rem 0;
            border-bottom: 1px solid #f3f3f3;
          }
        }
      }
    }
  }
}
