.item__stat{
    margin-right: 1.75rem;

    .stat__title{
        font-size: .75rem;
    }
    .stat__data{
        font-size: 1.5rem;
        font-weight: 500;
    }
}