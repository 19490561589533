.icon__item{
    padding: 2rem;

    .icon__img{
        height: 3.5rem;
        width: 3.5rem;
    }
    .icon__description{
        margin-top: .5rem;
        color: white;
        font-size: 1.1rem;
    }
}