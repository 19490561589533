.details__header {
  background: #7164ff;
  min-height: 96px;
  display: flex;
  align-items: center;
  padding: 0rem 2rem;
  font-weight: 700;
  font-size: 1.1rem;
  color: white;
  letter-spacing: 0.25rem;
}
