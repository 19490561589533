@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap);
.icon__item{padding:2rem}.icon__item .icon__img{height:3.5rem;width:3.5rem}.icon__item .icon__description{margin-top:.5rem;color:white;font-size:1.1rem}

.left__pane{min-height:100vh;display:-webkit-flex;display:flex;height:100vh;-webkit-flex-direction:column;flex-direction:column}@media screen and (max-width: 720px){.left__pane{min-height:-webkit-fit-content;min-height:-moz-fit-content;min-height:fit-content;height:auto}}.left__pane .left__hero{padding:2rem 2rem 0rem 2rem;-webkit-flex:1 1;flex:1 1;background:#7164ff}@media screen and (max-width: 720px){.left__pane .left__hero{padding:2rem 2rem 4rem 2rem}}.left__pane .left__hero .left__logo{font-weight:700;font-size:1.1rem;color:white;letter-spacing:0.25rem}.left__pane .left__hero .left__img__wrapper{margin-top:4rem;display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:center;justify-content:center}@media screen and (max-width: 720px){.left__pane .left__hero .left__img__wrapper{margin-top:2rem}}.left__pane .left__hero .left__img__wrapper>img{width:50%}@media screen and (max-width: 720px){.left__pane .left__hero .left__img__wrapper>img{width:75%}}.left__pane .left__icons__wrapper{-webkit-flex:1 1;flex:1 1;background:#6156dc;padding:2.5rem 2.5rem 2rem 2.5rem}@media screen and (max-width: 720px){.left__pane .left__icons__wrapper{display:none}}.left__pane .left__icons__wrapper .left__title{font-size:2rem;color:white}.left__pane .left__icons__wrapper .icons__container{margin-top:1rem}

.applist__header{background:white;padding:4rem 2.5rem 1rem 2.5rem;display:-webkit-flex;display:flex;-webkit-justify-content:space-between;justify-content:space-between;-webkit-align-items:center;align-items:center}@media screen and (max-width: 720px){.applist__header{padding:1.5rem 2.5rem 1.5rem 2.5rem}}.applist__header .title{font-weight:500;font-size:1.75rem}.applist__header .icon>i{font-size:1.75rem;color:#a2a2a2}

.applist__wrapper{width:90%;margin:auto;height:calc(100vh - 148px);overflow-y:scroll}.applist__wrapper::-webkit-scrollbar{width:6px}.applist__wrapper::-webkit-scrollbar-thumb{background:#e8e8e8}.applist__wrapper::-webkit-scrollbar-track{background:none}@media screen and (max-width: 720px){.applist__wrapper{height:calc(50vh - 12px);width:100%}}.applist__wrapper a{color:black !important}

.item__stat{margin-right:1.75rem}.item__stat .stat__title{font-size:.75rem}.item__stat .stat__data{font-size:1.5rem;font-weight:500}

.applist__item{margin:1rem auto;background:#fff;padding:1.5rem 2rem;box-shadow:0px 0px 8px 0px #f0f0f0;border-radius:5px;width:95%}.applist__item .item__header{display:-webkit-flex;display:flex;-webkit-flex-direction:row;flex-direction:row;-webkit-justify-content:space-between;justify-content:space-between}.applist__item .item__header .item__left{display:-webkit-flex;display:flex}.applist__item .item__header .item__left .square__thumb{width:48px;height:48px;border-radius:5px}.applist__item .item__header .item__left .item__details{margin-left:.5rem}.applist__item .item__header .item__left .item__details .item__app{font-size:1.25rem;font-weight:500}.applist__item .item__header .item__left .item__details .item__publisher{color:#666665;font-size:.65rem}.applist__item .item__header .item__icon{color:#c3c3c3}.applist__item .item__header .item__icon>i{font-size:1.25rem}.applist__item .item__stats{margin-top:1rem;display:-webkit-flex;display:flex}

.applist__shimmer{background:white;border-radius:5px;padding:1rem 1rem;margin:1.25rem 0}

.applist__pane{min-height:100vh;background:#F7F7F7}@media screen and (max-width: 720px){.applist__pane{min-height:0}}

.details__header{background:#7164ff;min-height:96px;display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center;padding:0rem 2rem;font-weight:700;font-size:1.1rem;color:white;letter-spacing:0.25rem}

.details__wrapper{padding:2rem;width:100%}.details__wrapper .details__content__header{display:-webkit-flex;display:flex;-webkit-align-items:center;align-items:center}.details__wrapper .details__content__header .back__icon{color:#c3c3c3;font-size:1.5rem;margin-right:2rem}.details__wrapper .details__content__header .square__thumb{height:64px;width:64px;border-radius:5px;margin:0.75rem}.details__wrapper .details__content__header .app__details .app__name{font-weight:500;font-size:1.5rem}.details__wrapper .details__content__header .app__details .app__publisher{font-size:0.75rem}.details__wrapper .details__content{margin-top:3rem;background:white;padding:2rem;box-shadow:0px 0px 2px 1px #e7e7e7}@media screen and (max-width: 720px){.details__wrapper .details__content{overflow-x:scroll}}.details__wrapper .details__content .details__table{width:100%}.details__wrapper .details__content .details__table>thead{text-align:left}.details__wrapper .details__content .details__table>thead th{padding:1rem 0 1.5rem 0;font-weight:500}.details__wrapper .details__content .details__table>tbody>tr>td{padding:0.6rem 0;border-bottom:1px solid #f3f3f3}

*{margin:0;box-sizing:border-box;padding:0;font-family:"Poppins", sans-serif;font-size:16px}a{text-decoration:none}body{overflow-x:hidden}img{display:block}

